import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {Home} from "./pages/Home"
import {DeleteAccount} from "./pages/DeleteAccount"

function App() {

  return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="delete_account" element={<DeleteAccount />} />
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
