import React from "react";

export const Home = () => {

  let nombre = "Gustavo";
  let web = "vidarte.com";

  return (
      <div>
        <h1>Mi primer componente</h1>
        <p>Este es un texto en mi componente</p>
        <p>Mi nombre es: {nombre}</p>
        <p>Mi web es: {web}</p>
      </div>
  )
}