import React from "react";
import {DeleteAccountForm} from "../components/DeleteAccountForm";

export const DeleteAccount = () => {

  return (
      <div className={'delete_account_form'}>
        <h1>Borrar cuenta</h1>
        <DeleteAccountForm/>
      </div>
  )
}