import React, {useState} from "react";

export const DeleteAccountForm = (props) => {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [usernameError, setUsernameError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const deleteAccountClick = () => {

    setUsernameError('')
    setPasswordError('')

    // Check if the user has entered both fields correctly
    if ('' === username) {
      setUsernameError('Introduzca su nombre de usuario')
      return
    }

    if ('' === password) {
      setPasswordError('Introduzca su contraseña')
      return
    }

    deleteAccount()
  }

  const deleteAccount = () => {

    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin','http://localhost:3000');

    fetch("http://localhost:8080/v1/user/delete_account", {
      mode: 'cors',
      method: 'DELETE',
      body: JSON.stringify({username, password}),
      headers: headers
    })
    .then(response => {
      console.log(response)

      switch (response.status) {
        case 400: setPasswordError('Contraseña incorrecta'); break;
        case 404: setUsernameError('Usuario incorrecto'); break;
        case 204: success(); break;
        default: setUsernameError('Error desconocido'); break;
      }
    })
  }

  const success = () => {

    window.alert("Cuenta eliminada")
    setUsername('')
    setPassword('')
  }

  return (
      <form>
        <label>
          <span className={'title'}>Nombre de usuario:</span>
          <input
              value={username}
              type="text"
              name="username"
              onChange={(ev) => setUsername(ev.target.value)}
          />
        </label>
        <label className={'error'}>{usernameError}</label>
        <label>
          <span className={'title'}>Contraseña:</span>
          <input
              value={password}
              type="password"
              name="password"
              onChange={(ev) => setPassword(ev.target.value)}
          />
        </label>
        <label className={'error'}>{passwordError}</label>
        <input className={'button'} type="button" onClick={deleteAccountClick} value={'Enviar'}/>
      </form>
  )
}